import styled from "styled-components";
import {
    MaiButton,
    MaiFormContainer,
    MaiInputControl,
    MaiTextField
} from "../../style/MaiFormulary.style";
import React, {FormEvent, useState} from "react";
import API from "../../config/APIConfig";
import {Alert} from "../Alert";
import {useNavigate} from "react-router-dom";
interface IMaiLoginChangePwd{
    onHandleModelClose:Function,
    isOpen:boolean
}
const MaiLoginChangePwd = ({onHandleModelClose,isOpen}:IMaiLoginChangePwd) => {
    const [password, setPassword] = useState("");
    const navigate=useNavigate();
    const [statusMessage, setStatusMessage] = useState({
        title: "Message envoye",
        status: "success",
        message: "",
        scale: {transform: "scale(0)"},
    });
    const [loading, setLoading] = useState(false);
    const onHandleLogin = async (e: FormEvent) => {
        e.preventDefault();
        if (password.trim().length === 0) {
            setStatusMessage({
                title: "Champs vide",
                scale: {transform: "scale(1)"},
                message: "Champs mot de passe est vides",
                status: "error"
            })
            setTimeout(() => setStatusMessage({
                title: "Champs vide",
                status: "error",
                message: "",
                scale: {transform: "scale(0)"},
            }), 3000);
        } else {
           setLoading(true);
                API().post("/login/update", {password:password})
                    .then(res => {
                        setLoading(false);
                        setStatusMessage({
                            title: "Authentication",
                            status: "success",
                            message: "Odas Mali Admin",
                            scale: {transform: "scale(1)"},
                        })
                        setTimeout(() => setStatusMessage({
                            title: "",
                            status: "success",
                            message: "",
                            scale: {transform: "scale(0)"},
                        }), 3000);
                        setPassword("");
                        onHandleModelClose();
                        sessionStorage.clear();
                        navigate("/")
                    })
                    .catch(() => {
                        setLoading(false);
                        setStatusMessage({
                            title: "Authentication",
                            status: "error",
                            message: "Server error",
                            scale: {transform: "scale(1)"},
                        });
                        setTimeout(() => setStatusMessage({
                           title: "",
                            status: "",
                            message: "",
                            scale: {transform: "scale(0)"},
                        }), 3000);
                    });
        }

    }
    const onHandleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.parentElement!.classList.remove("active");
    }
    const onHandleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.parentElement!.classList.add("active");
    }
    return <MaiModelOverlay style={isOpen?
        {clipPath: "circle(141.4% at 100% 0)",backgroundColor:"rgba(0,0,0,0.7)"}:
        {clipPath: "circle(0% at 100% 0)",backgroundColor:"#d70b51"}}>
        <div className="mai-modal">
            <h1 style={{textAlign:"center",marginTop:"2rem",color:"white"}}>Change mot de passe</h1>
            <Alert title={statusMessage.title}
                   content={statusMessage.message}
                   type={statusMessage.status} style={statusMessage.scale}/>
            <div className="mai-close-model" id="mai-btn-model-close" onClick={()=>onHandleModelClose()}>
                <svg className="ionicon" viewBox="0 0 512 512">
                    <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={32}
                        d="M368 368L144 144m224 0L144 368"
                    />
                </svg>
            </div>
            <form id="form" method="post" onSubmit={(event) => onHandleLogin(event)}>
                <MaiInputControl className="form-control" style={{height: "3.5rem"}}>
                    <div className="icon-input">
                        <svg className="ionicon" viewBox="0 0 512 512">
                            <path
                                d="M218.1 167.17c0 13 0 25.6 4.1 37.4-43.1 50.6-156.9 184.3-167.5 194.5a20.17 20.17 0 00-6.7 15c0 8.5 5.2 16.7 9.6 21.3 6.6 6.9 34.8 33 40 28 15.4-15 18.5-19 24.8-25.2 9.5-9.3-1-28.3 2.3-36s6.8-9.2 12.5-10.4 15.8 2.9 23.7 3c8.3.1 12.8-3.4 19-9.2 5-4.6 8.6-8.9 8.7-15.6.2-9-12.8-20.9-3.1-30.4s23.7 6.2 34 5 22.8-15.5 24.1-21.6-11.7-21.8-9.7-30.7c.7-3 6.8-10 11.4-11s25 6.9 29.6 5.9c5.6-1.2 12.1-7.1 17.4-10.4 15.5 6.7 29.6 9.4 47.7 9.4 68.5 0 124-53.4 124-119.2S408.5 48 340 48s-121.9 53.37-121.9 119.17zM400 144a32 32 0 11-32-32 32 32 0 0132 32z"
                                fill="none"
                                stroke="currentColor"
                                strokeLinejoin="round"
                                strokeWidth={32}
                            />
                        </svg>
                    </div>
                    <MaiTextField value={password} name="password"
                                  onChange={(e) => setPassword(e.target.value)}
                                  onBlur={(e) => onHandleBlur(e)}
                                  onFocus={(e) => onHandleFocus(e)}
                                  type="password"
                                  placeholder="Mot de passe"/>
                </MaiInputControl>
                <MaiFormContainer>
                    <MaiButton type="submit" style={
                        loading ? {pointerEvents: "none", height: "3.5rem", opacity: 0.3} :
                            {pointerEvents: "initial", height: "3.5rem", opacity: 1}
                    }>
                <span className="icon-button">
                   <svg className="ionicon" viewBox="0 0 512 512">
                    <path
                        d="M336 208v-95a80 80 0 00-160 0v95"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={32}
                    />
                    <rect
                        x={96}
                        y={208}
                        width={320}
                        height={272}
                        rx={48}
                        ry={48}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={32}
                    />
                  </svg>
                </span>
                        <span className="text-button">{loading ? "Envoi en cours..." : "Envoyer"}</span>
                    </MaiButton>
                </MaiFormContainer>
            </form>
        </div>
    </MaiModelOverlay>
}

const MaiModelOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  padding-top: 10rem;
  overflow: hidden;
  clip-path: circle(0% at 100% 0);
  transition: 0.5s cubic-bezier(1,.12,.41,1.05);
  .mai-modal {
    width: 25rem;
    height: 40%;
    background-color: transparent;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    form {
      flex: 6;
    }
    .mai-close-model{
      position: absolute;
      right: 30%;
      top: 10%;
      transform: translateX(-20%);
      background-color: #d70b51;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      color: white;
      border-radius: 3rem;
      cursor: pointer;
      transition: .3s ease-in-out;
      &:hover{
        background: linear-gradient(120deg, #bf0847, #a60f40);
        transform: scale(1.07);
      }
      svg{
        width:80%
      }
    }
  }
`
export default MaiLoginChangePwd;