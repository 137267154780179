import {Header} from "../style/MaiHeader.styled";
import {motion} from "framer-motion";
import {Link} from "react-scroll";
import SvgComponent from "./AnimationSvg";
import {URL_IMAGE} from "../config/Config";

const MaiHeader = () => {
    const icon = {
        hidden: {
            transform: "scale(0)",
            fillOpacity: 0,
        },
        visible: {
            transform: "scale(1)",
            fillOpacity: 1,
        },
    };
    return (
      <Header id="home">
        <motion.div className="mai-left-side">
          <div className="logo">
            <svg
              version="1.1"
              id="svg2"
              width="302.20038"
              height="63.846855"
              viewBox="0 0 302.20038 63.846855"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs id="defs6">
                <clipPath clipPathUnits="userSpaceOnUse" id="clipPath20">
                  <path d="M 0,70.977 H 269.498 V 0 H 0 Z" id="path18" />
                </clipPath>
              </defs>

              <g
                id="page"
                transform="matrix(1.3333333,0,0,-1.3333333,-28.864265,79.239252)"
              >
                <path
                  d="M 0,0 H 269.498 V 70.977 H 0 Z"
                  style={{
                    display: "none",
                    fill: "#808080",
                    fillOpacity: 1,
                    fillRule: "nonzero",
                    stroke: "none",
                  }}
                  id="path12"
                />
                <g id="g14">
                  <g id="g16" clipPath="url(#clipPath20)">
                    <g
                      id="letter_A2"
                      className="letter_A2"
                      transform="translate(192.2477,28.0792)"
                    >
                      <motion.path
                        d="m 0,0 -7.247,15.268 c -0.242,0.506 -0.961,0.506 -1.202,0 l -0.871,-1.835 -1.338,-2.815 c -0.083,-0.182 -0.083,-0.391 0,-0.571 L -4.415,-3.101 1.78,-16.154 c 0.11,-0.233 0.345,-0.38 0.602,-0.38 h 2.523 1.892 c 0.488,0 0.811,0.51 0.6,0.949 z"
                        variants={icon}
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: 0.6 }}
                        style={{
                          fillOpacity: 1,
                          fill: "#d70b51",
                          fillRule: "nonzero",
                          stroke: "none",
                        }}
                        id="path24"
                      />
                    </g>
                    <g id="letter_A1" transform="translate(178.1143,57.8523)">
                      <motion.path
                        variants={icon}
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: 0.5 }}
                        d="M 0,0 C -0.073,0.152 -0.182,0.275 -0.319,0.358 -0.453,0.446 -0.613,0.492 -0.78,0.492 -0.945,0.492 -1.105,0.446 -1.239,0.358 -1.376,0.275 -1.488,0.152 -1.558,0 L -12.55,-23.153 -23.089,-45.356 c -0.209,-0.442 0.112,-0.952 0.6,-0.952 h 1.892 2.525 c 0.257,0 0.49,0.15 0.6,0.38 l 9.728,20.494 9.772,20.591 c 0.086,0.18 0.086,0.389 0,0.569 l -0.947,1.995 z"
                        style={{
                          fill: "#dcdcdc",
                          fillOpacity: 1,
                          fillRule: "nonzero",
                          stroke: "none",
                        }}
                        id="path28"
                      />
                    </g>
                    <g id="letter_O1" transform="translate(41.0692,58.9491)">
                      <motion.path
                        variants={icon}
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: 0.2 }}
                        d="m 0,0 c -5.399,-1.086 -10.155,-3.971 -13.609,-7.999 -3.622,-4.223 -5.812,-9.706 -5.812,-15.697 0,-5.99 2.19,-11.473 5.812,-15.696 3.454,-4.028 8.21,-6.911 13.609,-7.999 0.409,-0.082 0.789,0.239 0.789,0.657 v 1.894 2.173 c 0,0.308 -0.213,0.57 -0.512,0.642 -4.037,0.991 -7.568,3.283 -10.122,6.404 -2.661,3.252 -4.259,7.405 -4.259,11.925 0,4.521 1.598,8.674 4.259,11.926 2.554,3.121 6.085,5.413 10.122,6.404 0.299,0.072 0.512,0.334 0.512,0.641 v 2.174 1.896 C 0.789,-0.24 0.409,0.081 0,0"
                        style={{
                          fill: "#dcdcdc",
                          fillOpacity: 1,
                          fillRule: "nonzero",
                          stroke: "none",
                        }}
                        id="path32"
                      />
                    </g>
                    <g id="letter_O2" transform="translate(50.623,58.9481)">
                      <motion.path
                        variants={icon}
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: 0.3 }}
                        d="m 0,0 c -0.409,0.081 -0.789,-0.24 -0.789,-0.655 v -1.896 -2.174 c 0,-0.307 0.213,-0.569 0.512,-0.641 4.037,-0.991 7.568,-3.283 10.12,-6.404 2.661,-3.252 4.258,-7.405 4.258,-11.926 0,-4.52 -1.597,-8.671 -4.258,-11.923 -2.552,-3.121 -6.083,-5.413 -10.12,-6.404 -0.299,-0.074 -0.512,-0.336 -0.512,-0.644 v -2.171 -1.896 c 0,-0.416 0.38,-0.739 0.789,-0.655 5.397,1.086 10.155,3.969 13.609,7.999 3.619,4.223 5.81,9.704 5.81,15.694 0,5.991 -2.191,11.474 -5.81,15.697 C 10.155,-3.971 5.397,-1.088 0,0"
                        style={{
                          fill: "#dcdcdc",
                          fillOpacity: 1,
                          fillRule: "nonzero",
                          stroke: "none",
                        }}
                        id="path36"
                      />
                    </g>
                    <g id="letter_D" transform="translate(112.9151,58.3452)">
                      <motion.path
                        variants={icon}
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: 0.4 }}
                        d="m 0,0 h -7.977 -7.313 c -0.367,0 -0.664,-0.297 -0.664,-0.664 v -16.088 -16.087 c 0,-0.367 0.297,-0.666 0.664,-0.666 h 1.995 1.993 c 0.367,0 0.666,0.299 0.666,0.666 v 13.429 13.428 c 0,0.365 0.297,0.664 0.664,0.664 H -5.318 0 c 4.986,0 9.506,-2.028 12.781,-5.303 3.274,-3.274 5.3,-7.794 5.3,-12.778 0,-4.986 -2.026,-9.506 -5.3,-12.781 C 9.506,-39.454 4.986,-41.482 0,-41.482 h -4.021 -4.022 -1.296 -1.297 -2.659 -1.995 c -0.367,0 -0.664,-0.297 -0.664,-0.664 v -1.995 -1.993 c 0,-0.367 0.297,-0.666 0.664,-0.666 H -7.977 0 c 6.452,0 12.302,2.624 16.538,6.863 4.237,4.236 6.863,10.086 6.863,16.538 0,6.45 -2.626,12.299 -6.863,16.538 C 12.302,-2.624 6.452,0 0,0"
                        style={{
                          fill: "#dcdcdc",
                          fillOpacity: 1,
                          fillRule: "nonzero",
                          stroke: "none",
                        }}
                        id="path40"
                      />
                    </g>
                    <g id="letter_S" transform="translate(246.5066,30.1595)">
                      <motion.path
                        variants={icon}
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: 0.7 }}
                        d="m 0,0 c -1.138,1.934 -2.714,3.378 -4.448,4.549 -1.734,1.171 -3.626,2.07 -5.399,2.914 l -0.167,0.077 -0.165,0.079 c -1.918,0.912 -3.9,1.955 -5.566,3.226 -1.666,1.268 -3.015,2.762 -3.665,4.57 -0.512,1.424 -0.523,2.861 -0.088,4.151 0.433,1.29 1.312,2.431 2.582,3.259 1.723,1.123 3.883,1.44 6.188,1.005 2.129,-0.4 4.379,-1.44 6.524,-3.079 0.297,-0.226 0.719,-0.176 0.943,0.123 l 1.173,1.56 1.189,1.58 c 0.22,0.29 0.167,0.708 -0.123,0.93 -3.048,2.32 -6.335,3.729 -9.511,4.173 -3.353,0.47 -6.581,-0.139 -9.258,-1.886 -2.292,-1.494 -3.896,-3.606 -4.698,-5.99 -0.802,-2.382 -0.798,-5.034 0.125,-7.603 0.976,-2.712 2.756,-4.833 4.892,-6.562 2.136,-1.73 4.63,-3.068 7.032,-4.208 l 0.169,-0.079 0.167,-0.08 c 1.501,-0.714 3.031,-1.439 4.364,-2.296 1.337,-0.857 2.477,-1.846 3.207,-3.083 0.742,-1.266 1.094,-2.718 1.054,-4.125 -0.039,-1.406 -0.466,-2.766 -1.276,-3.85 -0.457,-0.611 -1.035,-1.14 -1.697,-1.582 -0.538,-0.36 -1.132,-0.664 -1.76,-0.912 -0.259,-0.101 -0.435,-0.343 -0.435,-0.622 v -2.285 -1.901 c 0,-0.442 0.422,-0.758 0.848,-0.644 1.33,0.361 2.584,0.899 3.72,1.598 1.378,0.843 2.582,1.92 3.538,3.197 1.449,1.938 2.229,4.294 2.323,6.711 C 1.877,-4.668 1.288,-2.189 0,0"
                        style={{
                          fill: "#dcdcdc",
                          fillOpacity: 1,
                          fillRule: "nonzero",
                          stroke: "none",
                        }}
                        id="path44"
                      />
                    </g>
                    <g id="g46" transform="translate(229.9577,16.9417)">
                      <motion.path
                        variants={icon}
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: 1 }}
                        d="M 0,0 C -1.141,0.387 -2.296,0.907 -3.472,1.569 -4.626,2.215 -5.799,3 -6.997,3.918 -7.302,4.153 -7.744,4.085 -7.959,3.766 l -1.073,-1.599 -1.123,-1.67 c -0.197,-0.293 -0.131,-0.69 0.148,-0.904 1.694,-1.283 3.368,-2.342 5.027,-3.184 1.539,-0.778 3.066,-1.371 4.589,-1.782 0.426,-0.114 0.848,0.2 0.848,0.642 v 1.865 2.237 C 0.457,-0.341 0.272,-0.092 0,0"
                        style={{
                          fill: "#dcdcdc",
                          fillOpacity: 1,
                          fillRule: "nonzero",
                          stroke: "none",
                        }}
                        id="path48"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div className="welcome">
            <h1>Bienvenue Chez ODAS</h1>
            <h2>
              * Votre Partenaire en Marketing Digital et Communication Visuelle
              *
            </h2>
            <Link
              spy={true}
              smooth={true}
              duration={500}
              offset={-50}
              className={"button"}
              to={"service"}
            >
              <span className="mai-btn-text">En savoir plus</span>
              <span className="mai-btn-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ionicon"
                  viewBox="0 0 512 512"
                >
                  <title>Arrow Down</title>
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="48"
                    d="M112 268l144 144 144-144M256 392V100"
                  />
                </svg>
              </span>
            </Link>
          </div>
        </motion.div>
        <div className="mai-right-side">
          <SvgComponent />
        </div>
        <div className="mai-header-background">
          <img
            src={URL_IMAGE + "odas_background.jpg"}
            loading={"lazy"}
            alt="odas_mali background flag"
          />
        </div>
      </Header>
    );
};

export default MaiHeader;
