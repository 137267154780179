import {MaiCardSection} from "./MaiCardSection";
import {IData} from '../interfaces/IData';
import {useInView} from "framer-motion";
import {useRef} from "react";

export const MaiSection = ({id, title, image, description, tiled,isAnimPositionLeft,type}: IData) => {
    const ref = useRef(null);
    let isInView = useInView(ref, {amount: 0.2, once: true});
    return <div style={isInView ? {
        marginBottom: "2rem",
        transition: "0.5s cubic-bezier(.17,.67,.66,1.06)",
        transform: isAnimPositionLeft?"translateX(0)":"translateY(0)",
        opacity: 1
    } : {
        marginBottom: "2rem",
        transition: "0.5s cubic-bezier(.17,.67,.66,1.06)",
        transform: isAnimPositionLeft?"translateX(-200px)":"translateY(200px)",
        opacity: 0
    }} ref={ref}>
        <MaiCardSection
            type={type}
            id={id}
            title={title}
            description={description}
            image={image}
            tiled={tiled}
            isAnimPositionLeft
        />
    </div>
};
