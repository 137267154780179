import {IDataCardMini} from "../interfaces/IDataCardMini";
import {MaiCard} from "../style/MaiCardMini.styled";
import {useRef} from "react";
import {useInView} from "framer-motion";
import AnimationPosition from "../utils/AnimationPosition";
import {URL_IMAGE} from "../config/Config";

export const MaiCardMini = ({image, title, description, animPosition}: IDataCardMini) => {
    const ref = useRef(null);
    let isInView = useInView(ref, {amount: 0.2, once: true});
    return <MaiCard ref={ref} style={isInView ? {
        transition: "0.5s",
        transform: AnimationPosition(animPosition, true),
        opacity: 1
    } : {
        transition: "1s",
        transform: AnimationPosition(animPosition, false),
        opacity: 0
    }}>
        <div className="mai-card-header">
            <div className="mai-card-img">
                <img src={URL_IMAGE+image} loading={"lazy"} alt={image}/>
            </div>
            <div className="mai-card-title">{title}</div>
        </div>
        <p className="mai-card-body">{description}</p>
    </MaiCard>
}