import styled from "styled-components";

export const MaiSectionCardMini = styled.section`
  width: 100%;
  height: 50rem;
  margin: 5rem 0;
  display: flex;
  flex-direction: column;

`;
export const MaiCardMiniContainer = styled.section`
  width: 90%;
  height: 100%;
  padding: 5rem 0;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit,minmax(20rem,1fr));
  justify-content: center;
  overflow: hidden;
`;

export const MaiTitle = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 200;
  .title {
    font-size: 2rem;
    font-family: Roboto, sans-serif;
    font-weight: 400;
  }

  .underline {
    width: 30px;
    height: 7px;
    border-radius: 10px;
    background-color: #d70b51;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    .title {
      font-size: 1.5rem !important;
      text-align: center
    }
  }
`;
export const MaiContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .mai-pin {
    position: fixed;
    bottom: 10%;
    transform: translateY(-10%);
    right: 0;
    width: 4rem;
    height: 4rem;
    z-index: 500;
    display: flex;
    background-color: #00a75f;
    border-radius: 4rem 0 0 4rem;
    color: white;
    transition: width 0.5s;
    overflow: hidden;
    &:hover {
      width: 12rem;
    }
    &:hover a span{
      transform:scale(1);
      margin-left: 1rem;
      font-size: 1.3rem;
      font-family: Roboto,sans-serif;
      font-weight: 300;
    }
    &:hover a svg{
      width: 3rem!important;
    }

    a {
      width: 100%;
      height: 4rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      fill: white;
      padding-inline:0.5rem ;
      overflow: hidden;
      text-decoration: none;
      color: white;
      span{
          transform:scale(0);
        }
      svg {
        width: 50rem;
        animation: scaleUpDown 2s infinite alternate;
      }
    }
    @keyframes scaleUpDown{
      to{
        transform: scale(1.1);
      }
    }
  }
`
export const MaiMain = styled.main`
  flex-grow: 1;
`;

export const SectionFormulary = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10rem 0;
  position: relative;
  overflow: hidden;
  z-index: 100;

  .badge-block {
    width: 100%;
    height: 40rem;
    position: absolute;
    z-index: -1;
    background-color: #f3f3f3;
    transition: 1s;
  }
  @media (max-width:  500px) {
    .badge-block {
      display: none;
    }
  }
`
export const SectionPartnership = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1),0 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  height: 30rem;
  margin-bottom: 1rem;
  overflow: hidden;

  .mai-card-partnership {
    display: flex;
    width: 80%;
    height: 25rem;
    justify-content: space-evenly;
    align-items: center;
    transition: 1s;
  }

  @media (max-width: 850px) {
    .mai-card-partnership {
      width: 25rem !important;
      flex-direction: column;
      height: 40rem;
    }
  }
`