import styled from "styled-components";
import {IDataPartnership} from "../interfaces/IDataPartnership";
import {URL_IMAGE} from "../config/Config";

export const PartnershipCard = ({id,image}:IDataPartnership) => {

    return <PartnerCard key={id}>
        <img src={URL_IMAGE+image} alt={image}/>
    </PartnerCard>
}

const PartnerCard=styled.div`
  width: 15rem;
  height: 20rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1rem 1rem 0;
  background-color: transparent;
  @media(max-width: 850px){
    img{
    width: 50%!important;
  }
     &:last-child img{
    width: 40%!important;
  }
  }
  @media (max-width: 368px) {
     font-size: 1rem!important;
  }
  img{
    width: 100%;
    object-fit: cover;
  }
  &:last-child img{
    width: 60%;
    object-fit: contain;
  }
  
`