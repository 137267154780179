import styled from "styled-components";

export const Header = styled.header`
  background: white fixed;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 600;
  padding-top: 2%;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0%, 100% 89%, 0 100%);
  .mai-header-background{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;
    &:after{
        content: "";
      position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg,rgba(0,0,0,0.2),rgba(0,0,0,1));
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      
    }
  }
  .mai-left-side {
    width: 50rem;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 6;
    transition: 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      height: 30%;
      overflow: hidden;
      svg {
        width: 100%;
        height: 45%;
        object-fit: contain;
      }
    }

    .welcome {
      text-align: center;
      display: flex;
      width: 100%;
      height: auto;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      overflow: hidden;

      h1 {
        font-size: 3rem;
        color: white;
        font-family: Roboto, sans-serif;
        font-weight: 300;
      }

      h2 {
        margin-top: 2rem;
        color: white;
        font-weight: 300;

      }

      .button {
        margin-top: 2rem;
        border: 3px solid rgb(215, 11, 81);
        outline: none;
        width: 20rem;
        height: 3.3rem;
        padding: 0;
        border-radius: 20px;
        font-size: 1.3em;
        
        background-color: transparent;
        cursor: pointer;
        transition: 0.5s;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        .mai-btn-icon{
          display: none;
        }
        .mai-btn-text{
          font-family: Roboto, sans-serif;
          font-weight: 300;
          display: flex;
        }
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
          background-color: rgb(215, 11, 81);
          clip-path: circle(0 at 50% 50%);
          transition: 0.5s;
        }

        &:hover::after {
          clip-path: circle(100% at 50% 50%);
        }
      }
    }
  }

  .mai-right-side {
    width: 50rem;
    height: 100%;
    position: fixed;
    right: 0;
    top: 15%;
    z-index: 5;
    overflow: hidden;
    transition: 0.3s ease-in-out;

    svg {
      width: 90%;
      object-fit: contain;
    }
  }
  @media (max-width:1440px){
    .mai-left-side{
      width: 40rem;
    }
    .mai-right-side{
      width: 40rem;
    }
  }
   @media (max-width:1140px){
    .mai-left-side{
      width: 35rem;
    }
    .mai-right-side{
      width: 35rem;
    }
  }
  @media (max-width:1080px){
    .mai-left-side{
      width: 30rem;
    }
    .mai-right-side{
      width: 30rem;
    }
  }
  @media (max-width:980px){
    flex-direction: column;
    .mai-left-side{
      width: 20rem;
      top: 25%;
      left: 50%;
      transform: translateX(-50%);
      .logo{
        height: 10%;
         width: 90%;
        svg{
          width: 90%;
        }
      }
      .welcome{
        width: 100%;
        height: auto;
        h1{
          font-size: 2rem;
        }
        h2{
          font-size: 1.3rem;
        }
        .button{
          width: 70%;
        }
      }
      
    }
    .mai-right-side{
      width: 30rem;
      top:10%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  @media (max-width:545px){
    .mai-left-side{
      width: 20rem;
      .logo{
        width: 100%;
        svg{
          width: 60%;
        }
      }
      .welcome{
        h1{
          font-size: 1.8rem;
        }
        h2{
          font-size: 1.2rem;
        }
        .button{
          width: 65%;
          height: 3rem;
        }
      }
      
    }
    .mai-right-side{
      width: 25rem;
      top:10%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  @media (max-width:430px){
    .mai-left-side{
      .logo{
        svg{
          width: 80%;
        }
      }
      .welcome{
        h1{
          font-size: 1.7rem;
        }
        h2{
          font-size: 1.2rem;
        }
            .button {
        width: 3.5rem!important;
        height: 3.5rem!important;
        border-radius: 3.5rem!important;
        .mai-btn-text{
          display: none!important;
        }
        .mai-btn-icon{
          display: flex !important;
          z-index: 2;
          width: 1.5rem;
          height: 1.5rem;
          svg{
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
      }
      
    }
    .mai-right-side{
      width: 30rem;
      top:10%;
    }
  }
  @media (max-width:375px){
    flex-direction: column;
    .mai-left-side{
      width: 20rem;
      .logo{
         width: 70%;
        svg{
          width: 80%;
        }
      }
      .welcome{
        width: 100%;
        height: auto
      ;
        h1{
          font-size: 1.5rem;
        }
        h2{
          font-size: 1.2rem;
        }
      }
      
    }
    .mai-right-side{
      width: 23rem;
    }
  }
    @media (max-width:280px){
    flex-direction: column;
    .mai-left-side{
      width: 20rem;
      .logo{
         width: 70%;
        svg{
          width: 80%;
        }
      }
      .welcome{
        width: 100%;
        h1{
          font-size: 1.2rem;
        }
        h2{
          font-size: 1rem;
        }
      }
      
    }
    .mai-right-side{
      width: 15rem;
    }
  }
`
