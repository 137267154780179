import {createGlobalStyle} from "styled-components";
const GlobalStyle=createGlobalStyle`
    
    *,html{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: Roboto,sans-serif;
        font-weight: 300;
      overflow-x: hidden;
    }
  body{
    font-family: Roboto,sans-serif;
    font-weight: 300;
      background-color: white;
  }
  section{
    background-color: white;
    z-index: 100;
  }
`;

export default GlobalStyle;
