import {IData} from '../../interfaces/IData';
import styled from "styled-components";
import {URL_IMAGE} from "../../config/Config";
import React, {useRef, useState} from 'react';
import API from "../../config/APIConfig";

interface IItems extends IData {
    isEditing: boolean,
    setDataCurrent: Function,
    setNewDataCurrent: Function
}

const Items = ({
                   id,
                   title,
                   image,
                   description,
                   tiled,
                   isEditing,
                   setDataCurrent,
                   setNewDataCurrent,
                   type
               }: IItems) => {
    const [data, setData] = useState({
        image: isEditing ? image : "",
        title: isEditing ? title : "",
        description: isEditing ? description : "",
        tiled: isEditing ? tiled : false,
        type: type,
        id: isEditing ? id : null,
    });
    const refImage = useRef(null);
    const removedItemData = (id: number) => {
        switch (type) {
            case "services":
                setDataCurrent((prev: IData[]) => prev.filter((service: IData) => service.id !== id))
                break;
            case "about":
               setDataCurrent((prev: IData[]) => prev.filter((about: IData) => about.id !== id))
                break;
            case "contact":
                setDataCurrent((prev: IData[]) => prev.filter((contact: IData) => contact.id !== id))

                break;
            case "partnership":
                setDataCurrent((prev: IData[]) => prev.filter((partnership: IData) => partnership.id !== id))
                break;
            case "witness":
              setDataCurrent((prev: IData[]) => prev.filter((witness: IData) => witness.id !== id))
                break;
            default:
                break;
        }
    }
    const onHandleRemove = async (id: number | null) => {
        let response = window.confirm("Vous voulez cette section ?");
        if (response) {
            if (id !== null) {
                let res = await API().post("/admin/delete", {id: id});
                if (res.status === 200) {
                    alert("Section supprimee avec success");
                    removedItemData(id);
                } else {
                    alert("Erreure de suppression.");
                }
            } else {
                setNewDataCurrent([] as IData[])
            }
        }
    }
    const onHandleChangeTiled = (e: any) => {
        setData(prev => ({...prev, [e.target.name]: e.target.checked}))
    }

    const onHandleDescriptionChange = (e: any) => {
        setData(prev => ({...prev, [e.target.name]: e.target.value}))

    }

    const onHandleTitleChange = (e: any) => {
        setData(prev => ({...prev, [e.target.name]: e.target.value}))

    }
    const onHandleFileChange = (e: any) => {
        if (e.target.files[0].type.startsWith('image')) {
            setData(prev => ({...prev, [e.target.name]: e.target.files[0]}));
            (refImage.current! as HTMLImageElement).src = URL.createObjectURL(e.target.files[0])
        } else {
            alert("Ce n'est pas une image.\nVeuillez selectionner une image de type: *.webp,\n*.png,\n*.svg,\n*.jpg...")
        }


    }
    const checkFieldChange = async () => {

        if (data.image !== image) {
            let res = await API().post("/admin/update", {
                description: data.description,
                id: id,
                title: data.title,
                tiled: data.tiled,
                type: data.type,
                image: data.image
            }, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            if (res.status === 200) {
                alert("Mise a jour effectuee.")
            }
        } else {
            let res = await API().post("/admin/update", {
                description: data.description,
                id: id,
                title: data.title,
                tiled: data.tiled,
                type: data.type,
                image: data.image
            });
            if (res.status === 200) {
                alert("Mise a jour effectuee.")
            }
        }
    }
    const onHandleSubmitData = async (e: any) => {
        e.preventDefault();
        if (isEditing) {
            await checkFieldChange()

        } else {
            if (type === "partnership") {
                if (data.image !== null && data.image !== undefined && data.image.length !== 0) {
                    API().post("/admin/add", {...data}, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }).then(res => {
                        if (res.status === 200) {
                            alert("Une Section a ete ajouter.")

                        } else {
                            alert("Insert error?")
                        }
                        setNewDataCurrent([])
                        setDataCurrent((prev: IData[]) => [...prev, res.data.data]);
                    }).catch(err => console.log(err))
                }else{
                    alert("S'il vous plait ajouter une image du partenaire.")
                }
            } else {
                if (data.description.length === 0 || data.description === "") {
                    alert("Verifier que le champ descriptions n'est pas vide ?")
                } else {
                    API().post("/admin/add", {...data}, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }).then(res => {
                        if (res.status === 200) {
                            alert("Une Section a ete ajouter.")

                        } else {
                            alert("Verifier que le champ descriptions n'est pas vide?")
                        }
                        setNewDataCurrent([])
                        setDataCurrent((prev: IData[]) => [...prev, res.data.data]);
                    }).catch(err => console.log(err))
                }
            }
        }
    }
    const iconAction = () => {
        if (!isEditing) {
            return (<svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"
                      d="M416 128L192 384l-96-96"/>
            </svg>);
        } else {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
                    <path d="M384 224v184a40 40 0 01-40 40H104a40 40 0 01-40-40V168a40 40 0 0140-40h167.48" fill="none"
                          stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/>
                    <path
                        d="M459.94 53.25a16.06 16.06 0 00-23.22-.56L424.35 65a8 8 0 000 11.31l11.34 11.32a8 8 0 0011.34 0l12.06-12c6.1-6.09 6.67-16.01.85-22.38zM399.34 90L218.82 270.2a9 9 0 00-2.31 3.93L208.16 299a3.91 3.91 0 004.86 4.86l24.85-8.35a9 9 0 003.93-2.31L422 112.66a9 9 0 000-12.66l-9.95-10a9 9 0 00-12.71 0z"/>
                </svg>
            )
        }
    }
    return <Main>
        <div className='action'>
            <button className="mai-trash" onClick={() => onHandleRemove(id!)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
                    <path d="M112 112l20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320" fill="none"
                          stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/>
                    <path stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32"
                          d="M80 112h352"/>
                    <path
                        d="M192 112V72h0a23.93 23.93 0 0124-24h80a23.93 23.93 0 0124 24h0v40M256 176v224M184 176l8 224M328 176l-8 224"
                        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                        strokeWidth="32"/>
                </svg>
                <span>Supprimer</span>
            </button>
            <button onClick={(e) => onHandleSubmitData(e)} className="mai-submit">
                {iconAction()}
                <span>{isEditing ? "Modifier" : "Valider"}</span>
            </button>
            <div className='tiled'>
                <span>Enumerer</span>
                <input type="checkbox" checked={data.tiled} name="tiled" onChange={(e) => onHandleChangeTiled(e)}/>
            </div>
        </div>
        <Container>
            <Left>
                <img ref={refImage} src={isEditing ? URL_IMAGE + image : ""} alt={title + " image"}/>
                <label className="mai-input-file">
                    <input type="file" name="image" onChange={(e: any) => onHandleFileChange(e)}/>
                </label>
                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
                    <path
                        d="M350.54 148.68l-26.62-42.06C318.31 100.08 310.62 96 302 96h-92c-8.62 0-16.31 4.08-21.92 10.62l-26.62 42.06C155.85 155.23 148.62 160 140 160H80a32 32 0 00-32 32v192a32 32 0 0032 32h352a32 32 0 0032-32V192a32 32 0 00-32-32h-59c-8.65 0-16.85-4.77-22.46-11.32z"
                        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                        strokeWidth="32"/>
                    <circle cx="256" cy="272" r="80" fill="none" stroke="currentColor" strokeMiterlimit="10"
                            strokeWidth="32"/>
                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                          strokeWidth="32" d="M124 158v-22h-24v22"/>
                </svg>
            </Left>
            <Right style={type === "partnership" ? {display: "none"} : {display: "block"}}>
                <Title placeholder={"Titre"} value={data.title} name="title" onChange={(e) => onHandleTitleChange(e)}/>
                <Description placeholder={"Description"} value={data.description} name="description"
                             onChange={(e: any) => onHandleDescriptionChange(e)}/>
            </Right>
        </Container>
    </Main>
};

const Main = styled.main`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;

  .action {
    display: flex;
    justify-content: center;
    align-items: center;

    .tiled {
      height: 4rem;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2rem;
      border-radius: 10px;
      gap: 0.5rem;
      padding-inline: 1rem;

      input {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 5px;
        margin-left: 2rem;
      }

      span {
        font-family: Roboto, sans-serif;
        font-size: 1.2rem;
      }
    }

    button {
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #b70b51;
      background-color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border: none;
      outline: none;
      cursor: pointer;
      border-radius: 10px;
      margin-inline: 2rem;
      padding-inline: 1rem;
      gap: 0.5rem;

      span {
        font-size: 1.1rem;
      }

      &:hover {
        background-color: #d70b51;
        color: white;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .mai-submit {
      background-color: #059bef;
      color: white;

      &:hover {
        background-color: #067fc3;
        color: white;
      }
    }
  }
`;
const Container = styled.div`
  min-height: 30rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

`;
const Right = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  height: 35rem;
  overflow: hidden;

`;
const Left = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35rem;
  color: #d70b51;

  img {
    width: 80%;
    height: 80%;
    object-fit: contain;
  }

  .mai-input-file {
    position: absolute;
    left: 0;
    top: 0;
    width: 80%;
    height: 80%;
    cursor: pointer;

    input {
      width: 80%;
      height: 80%;
      display: none;
    }

    z-index: 3;
  }

  svg {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.3s;
    z-index: 1;
  }

  &:hover svg {
    transform: translate(-50%, -50%) scale(1);
  }

  &:hover img {
    filter: brightness(50%);
  }
`;
const Title = styled.input`
  color: #d70b51;
  font-family: "Roboto Medium", sans-serif;
  font-weight: 400;
  font-size: 2rem;
  height: 20%;
  width: 100%;
  border: none;

`;
const Description = styled.textarea`
  border: none;
  font-size: 1.5rem;
  min-width: 100%;
  height: 70%;
  font-family: "Roboto Light", sans-serif;
  font-weight: 300;
  line-height: 2rem;
  outline: none;
`;
export default Items;