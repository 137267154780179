import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";

const SidebarLeft=()=>{
    const router=useLocation();
    return <SidebarContainer>
            <ul>
                <MaiLiAdmin active={router.pathname==="/dashboard"}>
                    <NavLink to={"/dashboard"}>Services</NavLink>
                </MaiLiAdmin>
                <MaiLiAdmin active={router.pathname==="/dashboard/about"}><NavLink to={"/dashboard/about"}>About</NavLink></MaiLiAdmin>
                <MaiLiAdmin active={router.pathname==="/dashboard/contact"}><NavLink to={"/dashboard/contact"}>Contact</NavLink></MaiLiAdmin>
                <MaiLiAdmin active={router.pathname==="/dashboard/partnership"}><NavLink to={"/dashboard/partnership"}>Nos partenaires</NavLink></MaiLiAdmin>
                <MaiLiAdmin active={router.pathname==="/dashboard/witness"}><NavLink to={"/dashboard/witness"}>Temoignages</NavLink></MaiLiAdmin>
            </ul>
    </SidebarContainer>
}

const SidebarContainer=styled.div`
  position: fixed;
  top: 2.5rem;
  left: 0;
  width: 20rem;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;

  ul {
    width: 100%;
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    padding-left: 0;
  }
`;
const MaiLiAdmin=styled.li<{active:boolean}>`
      list-style-type: none;
      padding: 2rem 0;
      margin-top: 1.5rem;
      position: relative;
      overflow: hidden;
  background: ${props=>props.active?"rgba(230, 230, 230, 1)":"transparent"};
      a {
        text-decoration: none;
        font-family: Roboto, sans-serif;
        font-weight: 500;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        color: ${props=>props.active?"#d70b51":"grey"};
        position: absolute;
        left: 2rem;
        top: 0;
        width: 100%;
        height: 100%;
      }
      &:after{
        content: "";
        width: 7px;
        height: 50%;
        background: #d70b51;
        position: absolute;
        left:0;
        top: 50%;
        border-radius: 7px;
        transform: ${props=>props.active?"translateY(-50%) scale(1)":"translateY(-50%) scale(0)"};
        transform-origin: center;
        transition: 0.5s;
      }
      &:hover:after{
        transform: translateY(-50%) scale(1);
      }
      &:hover {
        background: rgba(230, 230, 230, 1);
      }
`;
export default SidebarLeft;