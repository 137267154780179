import React, {ChangeEvent, FormEvent, useRef, useState} from "react";
import {
    MaiButton,
    MaiForm,
    MaiFormContainer,
    MaiInputControl,
    MaiLogoForm,
    MaiTextArea,
    MaiTextField
} from "../style/MaiFormulary.style";
import Logo from "../Images/logo_transparent.png";
import {useInView} from "framer-motion";
import {Alert} from "./Alert";
import API from "../config/APIConfig";

export const MaiFormulary = () => {
    const onHandleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.parentElement!.classList.add("active");
    }
    const onHandleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.parentElement!.classList.remove("active");
    }
    const ref = useRef(null);
    let isInView = useInView(ref, {amount: 0.4, once: true});
    const [inputText, setInputText] = useState({
        fullName: "",
        email: "",
        subject: "",
        message: "",
        phone: "",
    });
    const [statusMessage, setStatusMessage] = useState({
        title:"Message envoye",
        status: "success",
        message: "",
        scale: {transform: "scale(0)"},
    });
    const [loading, setLoading] = useState(false);
    const onHandleSendMessage = async (e: FormEvent) => {
        e.preventDefault();
        const exp: RegExp = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
        let email = inputText.email.match(exp);
        if (inputText.fullName.trim().length === 0 || inputText.email.trim().length === 0
            || inputText.subject.trim().length === 0 || inputText.message.trim().length === 0) {
            setStatusMessage({
                title:"Champs vide",
                scale: {transform: "scale(1)"},
                message: "L'un des champs est vides",
                status: "error"
            })
            setTimeout(() => setStatusMessage({
                title:"Champs vide",
                status: "error",
                message: "",
                scale: {transform: "scale(0)"},
            }), 3000);
        } 
        else if (inputText.phone.length !== 0 && inputText.phone.trim().match(new RegExp(/^[0-9]{8}$/g)) === null) {
            setStatusMessage({
                title:"Champs telephone",
                scale: {transform: "scale(1)"},
                message: "N'est pas un numero de telephone",
                status: "error"
            })
            setTimeout(() => setStatusMessage({
                title:"Champs telephone",
                status: "error",
                message: "",
                scale: {transform: "scale(0)"},
            }), 3000);
        } 
        else {
            if (email !== null) {
                setLoading(true);
                setStatusMessage({
                            title:"En cours d'envoi...",
                            status: "info",
                            message: "Veuillez patienter s'il vous plait...",
                            scale: {transform: "scale(1)"},
                })
                API().post("/mail", inputText)
                    .then(res => {
                        console.log(res)
                        setLoading(false);
                        setStatusMessage({
                            title: "Message a ete envoyer avec success.",
                            status: "success",
                            message: "Merci de nous contacter.",
                            scale: {transform: "scale(1)"},
                        })
                        setTimeout(() => setStatusMessage({
                            title: "Message a ete envoyer avec success.",
                            status: "success",
                            message: "",
                            scale: {transform: "scale(0)"},
                        }), 3000);
                        setInputText({
                            fullName: "",
                            subject:"",
                            phone: "",
                            message: "",
                            email: "",
                        })
                    })
                    .catch(() => {
                        setLoading(false);
                        setStatusMessage({
                            title: "Message non envoyer",
                            status: "error",
                            message: "Erreur d'envoi message. Ressayer plus tard",
                           scale: {transform: "scale(1)"},
                        });
                        setTimeout(() => setStatusMessage({
                            title: "Message non envoyer",
                            status: "error",
                            message: "",
                           scale: {transform: "scale(0)"},
                        }), 3000);
                    });
            } 
            else {
                setStatusMessage({
                    title: "Champs email",
                    scale: {transform: "scale(1)"},
                    message: "Votre email est incorrect",
                    status: "error"
                })
                setTimeout(() => setStatusMessage({
                    title: "Champs email",
                    status: "error",
                    message: "",
                   scale: {transform: "scale(0)"},
                }), 3000);
            }
        }

    }
    const onHandleChangeInputText = (e: ChangeEvent<HTMLInputElement>) => {
        let key = e.target.getAttribute("name") ?? "";
        let value = e.target.value;
        setInputText(prevState => ({...prevState, [key]: value}));
    }
    const onHandleChangeInputTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
        let key = e.target.getAttribute("name") ?? "";
        let value = e.target.value;
        setInputText(prevState => ({...prevState, [key]: value}));
    }
    return <MaiForm id="formContact" method="post" onSubmit={(event) => onHandleSendMessage(event)} ref={ref} style={
        isInView ? {transition: "0.4s cubic-bezier(.17,.67,.66,1.06)", transform: "translateY(0)", opacity: 1} :
            {transition: "0.4s cubic-bezier(.17,.67,.66,1.06)", transform: "translateY(200px)", opacity: 0}}>
        <MaiFormContainer>
            <MaiLogoForm className="logo">
                <img src={Logo} alt="odas-logo"/>
            </MaiLogoForm>
        </MaiFormContainer>
        <Alert style={statusMessage.scale} title={statusMessage.title} content={statusMessage.message}
               type={statusMessage.status}
        />
        <div>
            <MaiInputControl className="form-control">
                <div className="icon-input">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
                        <title>Person</title>
                        <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                              fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                              strokeWidth="32"/>
                        <path
                            d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                            fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32"/>
                    </svg>
                </div>
                <MaiTextField value={inputText.fullName} id="fullName" name="fullName" onChange={(e) => onHandleChangeInputText(e)}
                              onBlur={(e) => onHandleBlur(e)} onFocus={(e) => onHandleFocus(e)} type="text"
                              placeholder="Nom Complet"/>
            </MaiInputControl>
            <MaiInputControl>
                <div className="icon-input">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
                        <title>Mail</title>
                        <rect x="48" y="96" width="416" height="320" rx="40" ry="40" fill="none" stroke="currentColor"
                              strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/>
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                              strokeWidth="32" d="M112 160l144 112 144-112"/>
                    </svg>
                </div>
                <MaiTextField value={inputText.email} id="email" name="email"
                              onChange={(e) => onHandleChangeInputText(e)}
                              onBlur={(e) => onHandleBlur(e)}
                              onFocus={(e) => onHandleFocus(e)}
                              type="email"
                              placeholder="Email"/>
            </MaiInputControl>
            <MaiInputControl>
                <div className="icon-input">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
                        <title>Mail</title>
                        <rect x="48" y="96" width="416" height="320" rx="40" ry="40" fill="none" stroke="currentColor"
                              strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/>
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                              strokeWidth="32" d="M112 160l144 112 144-112"/>
                    </svg>
                </div>
                <MaiTextField value={inputText.phone} id="phone" name="phone"
                              onChange={(e) => onHandleChangeInputText(e)}
                              onBlur={(e) => onHandleBlur(e)}
                              onFocus={(e) => onHandleFocus(e)}
                              type="text"
                              placeholder="Telephone (Facultatif)"/>
            </MaiInputControl>
            <MaiInputControl>
                <div className="icon-input">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
                        <title>Mail</title>
                        <rect x="48" y="96" width="416" height="320" rx="40" ry="40" fill="none" stroke="currentColor"
                              strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/>
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                              strokeWidth="32" d="M112 160l144 112 144-112"/>
                    </svg>
                </div>
                <MaiTextField value={inputText.subject} id="subject" name="subject" onChange={(e) => onHandleChangeInputText(e)}
                              onBlur={(e) => onHandleBlur(e)} onFocus={(e) => onHandleFocus(e)} type="text"
                              placeholder="Objet"/>
            </MaiInputControl>
            <MaiFormContainer>
                <MaiTextArea value={inputText.message} id="message" name="message" onChange={(e) => onHandleChangeInputTextArea(e)}
                             placeholder="Ecrire votre message..."/>
            </MaiFormContainer>
            <MaiFormContainer>
                <MaiButton type="submit" style={loading?{pointerEvents:"none",opacity:0.3}:{pointerEvents:"initial",opacity:1}}>
                <span className="icon-button">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
                        <title>Send</title>
                        <path
                            d="M470.3 271.15L43.16 447.31a7.83 7.83 0 01-11.16-7V327a8 8 0 016.51-7.86l247.62-47c17.36-3.29 17.36-28.15 0-31.44l-247.63-47a8 8 0 01-6.5-7.85V72.59c0-5.74 5.88-10.26 11.16-8L470.3 241.76a16 16 0 010 29.39z"
                            fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round"
                            strokeWidth="32"/>
                    </svg>
                </span>
                    <span className="text-button">{loading?"Envoi en cours...":"Envoyer"}</span>
                </MaiButton>
            </MaiFormContainer>
        </div>
    </MaiForm>
}