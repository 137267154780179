import React, {useEffect, useRef, useState} from 'react';
import {MaiNavbar} from "../components/MaiNavbar";
import GlobalStyle from "../style/GlobalStyle";
import MaiHeader from "../components/MaiHeader";
import {MaiSection} from '../components/MaiSection';
import {useInView} from 'framer-motion';
import {MaiCardMini} from "../components/MaiCardMini";
import {
    MaiCardMiniContainer,
    MaiContainer,
    MaiMain,
    MaiTitle,
    SectionFormulary,
    SectionPartnership
} from '../style/MaiApp.styled';
import {MaiFormulary} from '../components/MaiFormulary';
import logoFooter from "../Images/logo_transparent.png";
import WebSiteImage from "../Images/website.png";
import {MaiFooter} from '../style/MaiFooter.styled';
import {PartnershipCard} from "../components/PartnershipCard";
import {HelmetProvider, Helmet} from "react-helmet-async";
import {IData} from "../interfaces/IData";
import {useLoaderData} from "react-router-dom";
import {IServerData} from "../interfaces/IServerData";
import {URL_IMAGE} from "../config/Config";

const App = () => {
    const refBadge = useRef(null);
    let isInViewBadge = useInView(refBadge, {amount: 0.2, once: true});
    const refFooter = useRef(null);
    let isInViewFooter = useInView(refFooter, {once: true});
    const refPartnership = useRef(null);
    let isInViewPartnership = useInView(refPartnership, {amount: 0.2, once: true});
    const description = "Nous sommes une entreprise digitale spécialisée dans le marketing digital et nous sommes déterminés à aider les entreprises à réussir en ligne."
    const title="Odas Mali - Une Entreprise de Marketing digital."
    const items=useLoaderData() as IServerData;

    const [data, setData] = useState({
        partnership:[] as IData[],
        services:[] as IData[],
        about:[] as IData[],
        contact:[] as IData[],
        witness:[] as IData[]
    });

    useEffect(()=>{setData(items)},[items])
    return (
      <HelmetProvider>
        <MaiContainer>
          <GlobalStyle />
          <Helmet>
            <title>{title}</title>
            <meta charSet="utf-8" />
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={WebSiteImage} />
            <meta property="og:url" content="https://www.odasmali.com" />
            <meta property="og:type" content="website" />
            <meta name="author" content="Odas Mali" />
          </Helmet>
          <MaiMain>
            <MaiNavbar />
            <MaiHeader />
            <section id="service" style={{ overflow: "hidden", zIndex: 100 }}>
              <MaiTitle>
                <span className="title">Services</span>
                <span className="underline"></span>
              </MaiTitle>
              {data.services.map((data) => (
                <MaiSection
                  type=""
                  id={null}
                  key={data.id}
                  title={data.title}
                  description={data.description}
                  image={data.image}
                  tiled={data.tiled}
                  isAnimPositionLeft={data.isAnimPositionLeft}
                />
              ))}
            </section>
            <section id="about" style={{ overflow: "hidden", zIndex: 100 }}>
              <MaiTitle>
                <h1 className="title">A Propos</h1>
                <span className="underline"></span>
              </MaiTitle>
              {data.about.map((data) => (
                <MaiSection
                  type=""
                  id={data.id}
                  key={data.id}
                  title={""}
                  description={data.description}
                  image={data.image}
                  tiled={false}
                  isAnimPositionLeft={false}
                />
              ))}
            </section>
            <section
              style={{
                overflow: "hidden",
                zIndex: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MaiTitle>
                <h1 className="title">Temoignages</h1>
                <span className="underline"></span>
              </MaiTitle>
              <MaiCardMiniContainer>
                {data.witness.map((item) => (
                  <MaiCardMini
                    key={item.id}
                    id={item.id!}
                    image={item.image}
                    title={item.title}
                    description={item.description.join("")}
                    animPosition={
                      parseInt((Math.random() * 10).toFixed(0)) % 2 === 0
                        ? "fromLeftToRight"
                        : "fromBottomToTop"
                    }
                  />
                ))}
              </MaiCardMiniContainer>
            </section>
            <section id="contact" style={{ overflow: "hidden", zIndex: 100 }}>
              <MaiTitle>
                <h1 className="title">Contact</h1>
                <span className="underline"></span>
              </MaiTitle>
              {data.contact.map((data) => (
                <MaiSection
                  type="contact"
                  id={null}
                  key={data.id}
                  title={""}
                  description={data.description}
                  image={""}
                  tiled={false}
                  isAnimPositionLeft={false}
                />
              ))}
            </section>
            <SectionFormulary ref={refBadge}>
              <div
                className="badge-block"
                style={
                  isInViewBadge
                    ? {
                        clipPath: "polygon(0 58%, 0 100%, 100% 45%, 100% 0)",
                      }
                    : {
                        clipPath: "polygon(0 58%, 0 100%, 0 100%, 0 58%)",
                      }
                }
              ></div>
              <MaiFormulary />
            </SectionFormulary>
            <SectionPartnership id="partnership">
              <MaiTitle style={{ height: "" }}>
                <h1 className="title">Nos Partenaires</h1>
                <span className="underline"></span>
              </MaiTitle>
              <div
                className="mai-card-partnership"
                ref={refPartnership}
                style={
                  isInViewPartnership
                    ? {
                        transform: "translateY(0)",
                        opacity: 1,
                        overflow: "hidden",
                      }
                    : {
                        transform: "translateY(200px)",
                        overflow: "hidden",
                        opacity: 0,
                      }
                }
              >
                {data.partnership.map((item) => (
                  <PartnershipCard
                    key={item.id}
                    image={item.image}
                    id={item.id!}
                  />
                ))}
              </div>
            </SectionPartnership>
          </MaiMain>

          <MaiFooter ref={refFooter}>
            <div
              className="logo"
              style={
                isInViewFooter
                  ? {
                      transition: "1s",
                      opacity: 1,
                      transform: "translateY(0)",
                    }
                  : {
                      transition: "1s",
                      opacity: 0,
                      transform: "translateY(-200px)",
                    }
              }
            >
              <img src={logoFooter} alt="logo" />
            </div>
            <div className="details">
              <div
                className="left-details"
                style={
                  isInViewFooter
                    ? {
                        transition: "0.5s",
                        opacity: 1,
                        transform: "translateY(0)",
                      }
                    : {
                        transition: "0.5s",
                        opacity: 0,
                        transform: "translateY(200px)",
                      }
                }
              >
                <div className="title">Info Contact</div>
                <div className="email">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <title>Mail</title>
                    <rect
                      x="48"
                      y="96"
                      width="416"
                      height="320"
                      rx="40"
                      ry="40"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                      d="M112 160l144 112 144-112"
                    />
                  </svg>
                  contact@odasmali.com
                </div>
                <div className="phone">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <title>Call</title>
                    <path
                      d="M451 374c-15.88-16-54.34-39.35-73-48.76-24.3-12.24-26.3-13.24-45.4.95-12.74 9.47-21.21 17.93-36.12 14.75s-47.31-21.11-75.68-49.39-47.34-61.62-50.53-76.48 5.41-23.23 14.79-36c13.22-18 12.22-21 .92-45.3-8.81-18.9-32.84-57-48.9-72.8C119.9 44 119.9 47 108.83 51.6A160.15 160.15 0 0083 65.37C67 76 58.12 84.83 51.91 98.1s-9 44.38 23.07 102.64 54.57 88.05 101.14 134.49S258.5 406.64 310.85 436c64.76 36.27 89.6 29.2 102.91 23s22.18-15 32.83-31a159.09 159.09 0 0013.8-25.8C465 391.17 468 391.17 451 374z"
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                    />
                  </svg>
                  (+223) 76 05 61 76
                </div>
                <div className="locate">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <title>Location</title>
                    <path
                      d="M256 48c-79.5 0-144 61.39-144 137 0 87 96 224.87 131.25 272.49a15.77 15.77 0 0025.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137z"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                    <circle
                      cx="256"
                      cy="192"
                      r="48"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    />
                  </svg>
                  Hamdallay ACI 2000 BP E 06333 - Bamako-Mali
                </div>
              </div>
              <div className="separator"></div>
              <div
                className="right-details"
                style={
                  isInViewFooter
                    ? {
                        transition: "0.5s",
                        opacity: 1,
                        transform: "translateY(0)",
                      }
                    : {
                        transition: "0.5s",
                        opacity: 0,
                        transform: "translateY(-200px)",
                      }
                }
              >
                <div className="title">Reseaux Sociaux</div>
                <a
                  href="https://www.facebook.com/profile.php?id=100091082088454"
                  className="facebook"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <title>OdasMali Facebook</title>
                    <path
                      d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z"
                      fillRule="evenodd"
                    />
                  </svg>
                  <span className="enum">Facebook</span>
                </a>
                <a href="https://wa.me/76056176" className="whatsapp">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <title>OdasMali Whatsapp</title>
                    <path
                      d="M414.73 97.1A222.14 222.14 0 00256.94 32C134 32 33.92 131.58 33.87 254a220.61 220.61 0 0029.78 111L32 480l118.25-30.87a223.63 223.63 0 00106.6 27h.09c122.93 0 223-99.59 223.06-222A220.18 220.18 0 00414.73 97.1zM256.94 438.66h-.08a185.75 185.75 0 01-94.36-25.72l-6.77-4-70.17 18.32 18.73-68.09-4.41-7A183.46 183.46 0 0171.53 254c0-101.73 83.21-184.5 185.48-184.5a185 185 0 01185.33 184.64c-.04 101.74-83.21 184.52-185.4 184.52zm101.69-138.19c-5.57-2.78-33-16.2-38.08-18.05s-8.83-2.78-12.54 2.78-14.4 18-17.65 21.75-6.5 4.16-12.07 1.38-23.54-8.63-44.83-27.53c-16.57-14.71-27.75-32.87-31-38.42s-.35-8.56 2.44-11.32c2.51-2.49 5.57-6.48 8.36-9.72s3.72-5.56 5.57-9.26.93-6.94-.46-9.71-12.54-30.08-17.18-41.19c-4.53-10.82-9.12-9.35-12.54-9.52-3.25-.16-7-.2-10.69-.2a20.53 20.53 0 00-14.86 6.94c-5.11 5.56-19.51 19-19.51 46.28s20 53.68 22.76 57.38 39.3 59.73 95.21 83.76a323.11 323.11 0 0031.78 11.68c13.35 4.22 25.5 3.63 35.1 2.2 10.71-1.59 33-13.42 37.63-26.38s4.64-24.06 3.25-26.37-5.11-3.71-10.69-6.48z"
                      fillRule="evenodd"
                    />
                  </svg>
                  <span className="enum">WhatsApp</span>
                </a>
                <a
                  href="https://www.linkedin.com/in/odas-mali-b9876126b/"
                  className="linkedIn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <title>OdasMali Linkedin</title>
                    <path d="M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z" />
                  </svg>
                  <span className="enum">LinkedIn</span>
                </a>
              </div>
            </div>
            <div className="mai-copy-right">Copyright © 2023 Odas Mali.</div>
            <div className="mai-header-background">
              <img
                src={URL_IMAGE + "odas_background.jpg"}
                loading={"lazy"}
                alt="odas_mali background flag"
              />
            </div>
          </MaiFooter>
          <div className="mai-pin">
            <a href="https://wa.me/76056176">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ionicon"
                viewBox="0 0 512 512"
              >
                <title>OdasMali Whatsapp</title>
                <path
                  d="M414.73 97.1A222.14 222.14 0 00256.94 32C134 32 33.92 131.58 33.87 254a220.61 220.61 0 0029.78 111L32 480l118.25-30.87a223.63 223.63 0 00106.6 27h.09c122.93 0 223-99.59 223.06-222A220.18 220.18 0 00414.73 97.1zM256.94 438.66h-.08a185.75 185.75 0 01-94.36-25.72l-6.77-4-70.17 18.32 18.73-68.09-4.41-7A183.46 183.46 0 0171.53 254c0-101.73 83.21-184.5 185.48-184.5a185 185 0 01185.33 184.64c-.04 101.74-83.21 184.52-185.4 184.52zm101.69-138.19c-5.57-2.78-33-16.2-38.08-18.05s-8.83-2.78-12.54 2.78-14.4 18-17.65 21.75-6.5 4.16-12.07 1.38-23.54-8.63-44.83-27.53c-16.57-14.71-27.75-32.87-31-38.42s-.35-8.56 2.44-11.32c2.51-2.49 5.57-6.48 8.36-9.72s3.72-5.56 5.57-9.26.93-6.94-.46-9.71-12.54-30.08-17.18-41.19c-4.53-10.82-9.12-9.35-12.54-9.52-3.25-.16-7-.2-10.69-.2a20.53 20.53 0 00-14.86 6.94c-5.11 5.56-19.51 19-19.51 46.28s20 53.68 22.76 57.38 39.3 59.73 95.21 83.76a323.11 323.11 0 0031.78 11.68c13.35 4.22 25.5 3.63 35.1 2.2 10.71-1.59 33-13.42 37.63-26.38s4.64-24.06 3.25-26.37-5.11-3.71-10.69-6.48z"
                  fillRule="evenodd"
                />
              </svg>
              <span>WhatsApp</span>
            </a>
          </div>
        </MaiContainer>
      </HelmetProvider>
    );
}
export default App;