import {createBrowserRouter} from "react-router-dom";
import App from "../screens/App";
import Dashboard from "../screens/admin/Dashboard";
import API from "../config/APIConfig";
import Services from "../screens/admin/Services";
import About from "../screens/admin/About";
import Contact from "../screens/admin/Contact";
import Partnership from "../screens/admin/Partnership";
import Witness from "../screens/admin/Witness";

const router =createBrowserRouter([{
    path:"/",
    element:<App/>,
    loader:async()=>(await API().get("/data")).data.data as {}
},
    {
    path:"/dashboard",
        element:<Dashboard/>,
        children:[
            {
                path:"/dashboard",
                element:<Services/>,
                loader:async()=>(await API().get("/data/services")).data.data as {}
            },
            {
                path:"/dashboard/about",
                element:<About/>,
                loader:async()=>(await API().get("/data/about")).data.data as {}
            },
            {
                path:"/dashboard/contact",
                element:<Contact/>,
                loader:async()=>(await API().get("/data/contact")).data.data as {}
            },
            {
                path:"/dashboard/partnership",
                element:<Partnership/>,
                loader:async()=>(await API().get("/data/partnership")).data.data as {}
            },
            {
                path:"/dashboard/witness",
                element:<Witness/>,
                loader:async()=>(await API().get("/data/witness")).data.data as {}
            }
        ]
    }
])

export  default router