import {Outlet, useNavigate} from "react-router-dom";
import AuthService from "../../auth/authService";
import {useEffect, useState} from "react";
import {MaiNavbarAdmin} from "../../components/admin/NavbarAdmin";
import SidebarLeft from "../../components/admin/SidebarLeft";
import styled from "styled-components";
import API from "../../config/APIConfig";
import {IData} from "../../interfaces/IData";

const Dashboard = () => {
    const [auth, setAuth] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        AuthService().then(res => res ? setAuth(res) : navigate("/")).catch(() => navigate("/"));
    }, [navigate])
    const [data, setData] = useState({
        partnership: [] as IData[],
        services: [] as IData[],
        about: [] as IData[],
        contact: [] as IData[],
        witness: [] as IData[]
    });

    useEffect(() => {
        API().get("/data").then(data => setData(data.data)).catch(err => console.log(err));
    }, [])
    return <div style={{overflow: "hidden"}}>
        {auth && (<><MaiNavbarAdmin/>
            <SidebarLeft/>
            <Main>
                <Outlet context={data}/>
            </Main></>)}
    </div>
}
const Main = styled.main`
  margin: 7rem 5rem 0 25rem;
  height: 100%;
`;
export default Dashboard;