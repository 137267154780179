import {motion} from "framer-motion";
import styled from "styled-components";

export const MaiNavAdmin = styled.nav`
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding-inline: 1rem;
  z-index: 1000;
  height: 5rem;
  transition: 0.5s cubic-bezier(.17, .67, .41, 1.07);
  
  .mai-action-right {
    display: flex;
    width: 50%;
    justify-content: space-around;
    align-items: center;

  }
  @media (min-width: 1166px) {
    .mai-action-right{
      justify-content: flex-end;
    }
  }
  @media (max-width: 1166px) {
    & {
      align-items: flex-start;
      padding-top: 1rem;
    }
  }

`;


export const MaiLogo = styled(motion.div)`
  width: 25%;
  height: 3rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  
`;
export const MaiButtonNav = styled.button`
  font-family: Roboto,sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  border: none;
  outline: none;
  background-color: #d70b51;
  padding-inline:1rem ;
  height: 2.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  @media (max-width: 680px) {
    display: none;
  }  
`;


export const MaiButtonLogOut = styled.button`
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  border: none;
  outline: none;
  background-color: #f10076;
  padding-inline: 1rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  @media (max-width: 680px) {
    display: none;
  }`;
