import API from "../config/APIConfig";
async function AuthService(){
    const user=sessionStorage.getItem("user");
    if (user===null){
        return false;
    }else{
        const token=JSON.parse(user).token;
        let result=await API().post("/auth",{},{
            headers:{
                Authorization: `Bearer ${token}`,
            }
        })
        if(result.status!==200){
            return false;
        }
        return result.data.auth
    }

}
export default AuthService;