import {useEffect, useState} from "react";
import {IData} from "../../interfaces/IData";
import AddItems from "./AddItems";
import Items from "./Items";
import {useLoaderData} from "react-router-dom";

interface IDisplayDataServer {
    type: string;
    name: string;
}
const DisplayDataServer=({type,name}:IDisplayDataServer)=>{
    const [newData,setNewData] = useState([] as IData[])
    const [data,setData]=useState([]as IData[]);
    const items=useLoaderData() as IData[];
    useEffect(()=>setData(items),[items])
    const onHandleAddedItem=()=>{
        setNewData(prev=>([{
            id:null,
            title:"",
            description:[],
            image:"",
            tiled:false,
            isAnimPositionLeft:true,
            type:type},...prev]))
    }
return <div>
    <AddItems onHandleAddItem={()=>onHandleAddedItem()} title={name}/>
    {newData&&newData.map(it=><Items
            setDataCurrent={setData}
            setNewDataCurrent={setNewData}
          isEditing={false}
          id={it.id}
          key={it.id}
           title={it.title}
           description={it.description}
           image={it.image}
           tiled={it.tiled}
           type={type}
           isAnimPositionLeft={it.isAnimPositionLeft}/>)}
    {data.map((item:IData)=>(<Items
            setDataCurrent={setData}
            setNewDataCurrent={setNewData}
          isEditing={true}
          id={item.id}
          key={item.id}
           title={item.title}
           description={item.description}
           image={item.image}
           tiled={item.tiled}
           type={item.type}
           isAnimPositionLeft={item.isAnimPositionLeft}/>))}
</div>
}

export default DisplayDataServer;