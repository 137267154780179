const AnimationPosition = (animPosition: string, isStart: boolean) => {
    if (!isStart) {
        switch (animPosition) {
            case "fromLeftToRight":
                return "translateX(-200px)";
            case "fromRightToLeft":
                return "translateX(200px)";
            case "fromBottomToTop":
                return "translateY(200px)";
            default:
                break;
        }
    } else {
        switch (animPosition) {
            case "fromLeftToRight":
                return "translateX(0)";
            case "fromRightToLeft":
                return "translateX(0)";
            case "fromBottomToTop":
                return "translateY(0)";
            default:
                break;
        }
    }
}

export  default AnimationPosition;