import styled from "styled-components";

export const MaiFooter = styled.footer`
  background: white fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 900;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40rem;
  transition: 1s;
  padding-inline: 1rem;
  clip-path: polygon(50% 0, 100% 17%, 100% 100%, 0 100%, 0 14%);
  .mai-header-background{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;
    &:after{
        content: "";
      position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg,rgba(0,0,0,0.2),rgba(0,0,0,1));
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      
    }
  }
  .mai-copy-right {
    color: #f3f3f3;
    font-size: 1rem;
    z-index: 10;
  }

  @media (max-width: 768px) {
    text-align: center;
    .separator {
      height: 5px !important;
      width: 10% !important;
      margin: 1rem 0;
    }

    .details {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      
      .left-details {
        overflow: hidden;

        .email,
        .phone,
        .locate {
          height: 4rem;
          margin-bottom: 1rem !important;
          text-align: left;

          svg {
            width: 1.5rem !important;
            height: 1.5rem !important;
          }
        }
      }

      .right-details {
        flex-direction: row !important;
        overflow: hidden;

        .facebook,
        .whatsapp,
        .linkedIn,
        .instagram {
          flex-direction: column;
          justify-content: space-evenly;
          margin: 0 2rem !important;
          gap: 0.5rem;

          .enum {
            font-family: Roboto, sans-serif;
            font-weight: 400;
            font-size: 1.3rem !important;
          }

          font-weight: 400;

          svg {
            width: 1.5rem !important;
            height: 1.5rem !important;
          }
        }
      }
    }

    .title {
      height: 5rem;
      display: none;

      &::after {
        display: none;
      }
    }
  }

  .details {
    width: 100%;
    min-height: 15rem;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    overflow: hidden;
    z-index: 10;
    .separator {
      width: 3px;
      border-radius: 3px;
      height: 100%;
      background-color: rgba(255, 246, 249, 0.49);
    }

    .title {
      display: none;
    }

    .right-details {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin: 0;
      overflow: hidden;

      .arrow {
        border-top: 10px solid transparent;
        border-left: 10px solid #d70b51;
        border-bottom: 10px solid transparent;
        margin-right: 0.5rem;
      }

      .title {
        color: white;
        font-size: 1.5rem;
        margin-bottom: 2rem;
        position: relative;
        font-family: Roboto, sans-serif;
        font-weight: 500;
        letter-spacing: 0.5px;

        &::after {
          content: "";
          width: 50%;
          height: 0.3rem;
          background-color: #d70b51;
          border-radius: 1rem;
          position: absolute;
          left: 0;
          bottom: -0.5rem;
        }
      }

      .enum {
        color: white;
        font-size: 1.3rem;
        transition: 0.5s;
      }

      .facebook,
      .whatsapp,
      .linkedIn,
      .instagram {
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: none;

        &:hover .enum,
        &:hover svg {
          color: #d70b51 !important;
          fill: #d70b51 !important;
        }
      }

      .facebook,
      .whatsapp,
      .linkedIn,
      .instagram {
        svg {
          width: 2rem;
          height: 2rem;
          margin-inline: 1rem;
          fill: white;
          transition: 0.5s;
        }
      }
    }

    .left-details {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      overflow: hidden;

      .title {
        color: white;
        font-size: 1.5rem;
        margin-bottom: 1rem;
        position: relative;
        font-family: Roboto, sans-serif;
        font-weight: 500;
        letter-spacing: 0.5px;

        &::after {
          content: "";
          width: 10%;
          height: 0.3rem;
          background-color: #d70b51;
          border-radius: 1rem;
          position: absolute;
          left: 0;
          bottom: -0.5rem;
        }
      }

      .email,
      .phone,
      .locate {
        display: flex;
        align-items: center;
        color: white;
        gap: 1rem;
        font-size: 1.5rem;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        letter-spacing: 1px;

        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    @media (max-width: 428px) {
      min-height: 10rem;
      .right-details {
        .facebook,
        .whatsapp,
        .linkedIn,
        .instagram {
          margin: 0 !important;
        }

        .enum {
          display: none !important;
        }

        flex-direction: row;
        justify-content: center;
        align-items: center;

        span {
          display: none;
        }
      }

      .left-details {
        .email,
        .phone,
        .locate {
          font-size: 1.2rem !important;
          overflow: hidden;

          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .logo {
      width: 50% !important;
    }
  }

  @media (max-width: 600px) {
    height: 30rem !important;
    .logo {
      margin-bottom: 0 !important;
    }
  }

  .logo {
    width: 40%;
    z-index: 50;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
    position: relative;

    img {
      width: 90%;
      height: 90%;
      object-fit: contain;
    }
  }

  @media (max-width: 300px) {
    .details {
      .left-details {
        .email,
        .phone,
        .locate {
          margin-left: 0.5rem;
          font-size: 1rem !important;
          svg {
            display: none;
          }
        }
      }
    }
  }
`;