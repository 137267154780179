import styled from "styled-components";
interface IAddItems {
    title:string,
    onHandleAddItem:Function
}
const AddItems=({title,onHandleAddItem}:IAddItems)=><AddContainer>
    <div className="title">
        <h2>{title}</h2>
    </div>
    <div className="actions">
        <button onClick={()=>onHandleAddItem()}>
            <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 112v288M400 256H112"/></svg>
        </button>
    </div>
</AddContainer>
const AddContainer=styled.div`
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 4rem;
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  .title {
    margin-left: 5rem;
  }

  .actions {
    button {
      width: 4rem;
      height: 4rem;
      background-color: #007cda;
      border: none;
      outline: none;
      color: white;
      cursor: pointer;
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }
`;
export const MaiButtonSave = styled.button`
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  border: none;
  outline: none;
  background-color: #007cda;
  padding-inline: 1rem;
  height: 4rem;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  margin-right: 5rem;
  @media (max-width: 680px) {
    display: none;
  }
`;
export default AddItems;