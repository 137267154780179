import {Link} from 'react-scroll';
import {MaiButtonNav, MaiLi, MaiLogo, MaiNav, MaiUL} from '../style/MaiNavbar.styled';
import React, {useState} from 'react';
import "../style/navbar.css";
import {useScroll, useTransform} from 'framer-motion';
import Logo from "../Images/logo_transparent.png";
import MaiModalLogin from "./MaiModalLogin";

export const MaiNavbar = () => {
    const [active, setActive] = useState({
        home: true,
        service: false,
        about: false,
        contact: false,
        partnership: false
    });
    const [isOpen,setIsOpen] = useState(false);
    let query = matchMedia("(max-width:600px)");
    const onHandleHamburger = () => {
        document.getElementById("mai-nav")!.classList.toggle("activeMenu")
        let ul = document.getElementById("mai-ul");
        ul!.classList.toggle("active");
        let link = Array.from<HTMLLIElement>(document.querySelectorAll("#mai-ul li"));
        if (ul!.classList.contains("active")) {
            link.forEach((item, index) => {
                item!.style.animationDelay = `${index * 100}ms`;
                item!.style.animationName = "waveText";
            });
            document.getElementById("Menu")!.classList.add("active");
        } else {
            link.forEach((item) => {
                item!.style.animationName = "";
            });
            document.getElementById("Menu")!.classList.remove("active");
        }
    };
    const onHandleActiveClass = (e: any) => {
        switch (e) {
            case "home":
                setActive({
                    home: true,
                    service: false,
                    about: false,
                    contact: false,
                    partnership: false
                });
                break;
            case "service":
                setActive({
                    home: false,
                    service: true,
                    about: false,
                    contact: false,
                    partnership: false
                });
                break;
            case "about":
                setActive({
                    home: false,
                    service: false,
                    about: true,
                    contact: false,
                    partnership: false
                });
                break;
            case "contact":
                setActive({
                    home: false,
                    service: false,
                    about: false,
                    contact: true,
                    partnership: false
                });
                break;
            case "partnership":
                setActive({
                    home: false,
                    service: false,
                    about: false,
                    contact: false,
                    partnership: true
                });
                break;
            default:
                break;
        }

    }
    const onHandlePressedNavTargetHide = () => {
        if (window.getComputedStyle(document.getElementById("Menu")!).display === "block") {
            onHandleHamburger();
        }
    }
    const {scrollYProgress} = useScroll();
    const scale = useTransform(scrollYProgress, [0, 0.10, 1], [0, 0.8, 1]);
    const onHandleCloseModel=()=>setIsOpen(false);

    const onHandleOpenModel=()=>setIsOpen(true);
    return (
        <>
            <MaiModalLogin onHandleModelClose={onHandleCloseModel} isOpen={isOpen}/>
        <MaiNav id="mai-nav">
            <MaiLogo style={query.matches ? {} : {scale}} className="logo">
                <Link to="home"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}><img src={Logo} alt="odas-logo"/></Link>
            </MaiLogo>
            <MaiUL id="mai-ul">
                <MaiLi active={active.home} className="nav-item">
                    <Link
                        to="home"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                        onSetActive={(e) => onHandleActiveClass(e)}
                        onClick={onHandlePressedNavTargetHide}
                    >
                        Accueil
                    </Link>
                </MaiLi>
                <MaiLi active={active.service} className="nav-item">
                    <Link
                        to="service"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                        onSetActive={(e) => onHandleActiveClass(e)}
                        onClick={onHandlePressedNavTargetHide}
                    >
                        Services
                    </Link>
                </MaiLi>
                <MaiLi active={active.about} className="nav-item">
                    <Link
                        to="about"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                        onSetActive={(e) => onHandleActiveClass(e)}
                        onClick={onHandlePressedNavTargetHide}
                    >
                        A Propos
                    </Link>
                </MaiLi>
                <MaiLi active={active.contact} className='nav-item'>
                    <Link
                        to="contact"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                        onSetActive={(e) => onHandleActiveClass(e)}
                        onClick={onHandlePressedNavTargetHide}
                    >
                        Contacts
                    </Link>
                </MaiLi>
                <MaiLi active={active.partnership} className='nav-item'>
                    <Link
                        to="partnership"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                        onSetActive={(e) => onHandleActiveClass(e)}
                        onClick={onHandlePressedNavTargetHide}
                    >
                        Nos Partenaires
                    </Link>
                </MaiLi>
            </MaiUL>
            <div className="mai-action-right">
                <MaiButtonNav onClick={onHandleOpenModel}>
                    Connexion
                </MaiButtonNav>
                <button className="Menu" id="Menu" onClick={onHandleHamburger}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </button>
            </div>
        </MaiNav>
            </>
    );
};
