import styled from "styled-components";

export const MaiCard=styled.div`
  min-width: 20rem;
  height: 20rem;

  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 14px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  .mai-card-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    height: 50%;
    transform: translateX(-50%);
  }

  .mai-card-img {
    width: 5rem;
    height: 5rem;
    border-radius: 7rem;
    overflow: hidden;
    border: 3px solid #f69ab7;

    img {
      width: 5rem;
      height: 5rem;
      border-radius: 7rem;
      object-fit: cover;
    }
  }

  .mai-card-title {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    margin-top: 1rem;
    text-align: center;
  }

  .mai-card-body {
    margin-top: 10rem;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    line-height: 1.5rem;
    height: 50%;
    padding-inline: 2rem;
  }

`;