import {motion} from "framer-motion";
import styled from "styled-components";

export const MaiNav = styled.nav`
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding-inline: 1rem;
  z-index: 1000;
  height: 5rem;
  transition: 0.5s cubic-bezier(.17, .67, .41, 1.07);

  &.activeMenu {
    height: 35rem;
  }

  .Menu {
    display: none;
  }

  .mai-action-right {
    display: flex;
    width: 25%;
    justify-content: space-around;
    align-items: center;

  }
  @media (min-width: 1166px) {
    .mai-action-right{
      justify-content: flex-end;
    }
  }
  @media (max-width: 1166px) {
    & {
      align-items: flex-start;
      padding-top: 1rem;
    }

    .Menu {
      width: 2.5rem;
      height: 2.5rem;
      display: block;
      cursor: pointer;
      border: none;
      outline: none;
      background-color: transparent;
      overflow: hidden;
      
      .line {
        width: 35px;
        height: 4px;
        margin-top: 5px;
        border-radius: 4px;
        background-color: #d70b51;
        transition: transform 0.5s;
      }

      .line:nth-child(2) {
        width: 25px;

      }

      .line:nth-child(3) {
        width: 35px;

      }

      &.active .line:nth-child(2) {
        transform: translateX(200px);
      }

      &.active .line:nth-child(1) {
        transform: rotate(45deg) translateY(6px);
      }

      &.active .line:nth-child(3) {
        transform: rotate(-45deg) translateY(-5px);
      }

      &.active .line {
        margin-top: 0
      }

    }
  }

`;
export const MaiUL = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  transition: 1s;
  overflow: hidden;
  z-index: 5;

  &.active {
    transform: translateY(20px) scale(1);
    display: flex;
  }
  @media (max-width: 1166px) {
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 30rem;
    transform: translateY(20px) scale(0);
  }
`;
export const MaiLi = styled.li<{ active: boolean }>`
  list-style: none;
  position: relative;
  padding: 15px 10px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s cubic-bezier(.17, .67, .41, 1.07);
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(.17, .67, .41, 1.07);
  animation-fill-mode: forwards;
  @media (max-width: 1166px) {
    transform: translateX(-200px) scale(0);
  }
  @media (max-width: 1300px) {
    a {
      font-size: 1.2rem !important;
    }
  }
  @media (max-width: 500px) {
    a {
      font-size: 1.2rem !important;
    }
  }
  @media(max-width: 400px){
    a {
      font-size: 1rem !important;
    }
  }
  @keyframes waveText{
    from{
      transform: translateX(-200px) scale(0);
      opacity: 0;
    }
    to{
      transform: translateX(0) scale(1);
      opacity: 1;
    }
  }
  &:hover a {
    color: #d70b51;
  }

  &:hover::after {
    transform: translateX(-50%);
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: ${props => props.active ? "translateX(-50%)" : "translateX(-300%)"};
    bottom: 0;
    height: 10px;
    width: 35%;
    border-radius: 10px;
    background-color: #c7c6c6;
    transition: 0.5s cubic-bezier(0.98, 0.41, 0.14, 0.53);
  }

  a {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    padding: 10px;
    font-size: 1.4em;
    text-wrap: none;
    line-height: 0;
    letter-spacing: 0.5px;
    transition: 0.5s ease-in-out;
    color: ${props => props.active ? "#d70b51" : "black"};
  }
`;
export const MaiLogo = styled(motion.div)`
  width: 25%;
  height: 3rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  a {
    height: 3rem;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  
`;
export const MaiButtonNav = styled.button`
  font-family: Roboto,sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  border: none;
  outline: none;
  background-color: #d70b51;
  padding-inline:1rem ;
  height: 2.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  @media (max-width: 680px) {
    display: none;
  }  
`;
export const MaiButtonSave = styled.button`
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  border: none;
  outline: none;
  background-color: #007cda;
  padding-inline: 1rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  @media (max-width: 680px) {
    display: none;
  }
`;

export const MaiButtonLogOut = styled.button`
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  border: none;
  outline: none;
  background-color: #f10076;
  padding-inline: 1rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  @media (max-width: 680px) {
    display: none;
  }`;
