import styled from "styled-components";

export const MaiForm=styled.form`
  width: 25rem;
  height: 40rem;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  margin-bottom: 1rem;
  transition: 1s;
`
export const MaiLogoForm=styled.div`
  text-align: center;
  img {
    width: 60%;
    height: 60%;
    object-fit: contain;
  }
`
export const MaiInputControl=styled.div`
  height: 3rem;
  margin-bottom: 1rem;
  background-color: #ededed;
  margin-inline: 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon-input {
    width: 12%;
    margin-inline: 0.5rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &.active {
    border: 3px solid #08a6ff;
  }
  &.active{
    color: #08a6ff;
  }
`;
export const MaiTextField=styled.input`
  border: none;
  outline: none;
  width: 100%;
  background-color: transparent;
  font-size: 1.2rem;
  font-family: Roboto,sans-serif;  
`;
export const MaiFormContainer=styled.div`
  margin-inline: 1rem;
`;
export const MaiTextArea=styled.textarea`
  border: 2px solid #ededed;
  border-radius: 10px;
  width: 100%;
  height: 7rem;
  padding: 0.5rem;
  font-size: 1.2rem;
  outline: none;
  &:focus {
    border:  3px solid #00a6ff;
  }

  font-family: Roboto, sans-serif;
`;
export const MaiButton=styled.button`
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 2rem;
  height: 3rem;
  border-radius: 10px;
  background: linear-gradient(120deg, #d70b51, #f8236b);
  transition: 0.5s;
  opacity: 1;
  cursor: pointer;
  .icon-button {
    width: 1.7rem;
    margin-left: 1rem;
    color: white;
    svg {
      width: 1.7rem;
      height: 1.7rem;
    }
  }

  &:hover,&:focus {
    background: linear-gradient(120deg, #bf0847, #a60f40);
  }

  .text-button {
    width: 90%;
    text-align: center;
    color: white;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
  }
`;