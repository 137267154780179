import styled from "styled-components";
import {IData} from '../interfaces/IData';
import {URL_IMAGE} from "../config/Config";
const MaiCard = styled.div<{ isImage: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin: 1rem 0;
  padding: 2rem 10%;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    gap: 2rem;
  }

  .mai-left-side {
    display: ${props => props.isImage ? "block" : "none"};
  }
`;
const MaiLeftSide = styled.div`
  width: 50%;
  height: 30rem;
  overflow: hidden;
  margin-right: 5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 1000px) {
    width: 100%;
    margin-right: 0;
    height: auto;
  }
`;
const MaiRightSide = styled.div<{ titleLength: number, isImage: boolean }>`
  width: 50%;

  .subtitle {
    line-height: 3rem !important;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    width: 100%;
    text-align: ${props => props.isImage ?"left":"center"};
    font-size: 1.5rem;
  }

  .title {
    margin-bottom: ${props => props.titleLength === 0 ? 0 : "3rem"};
    font-size: 1.7rem;
    color: #d70b51;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    text-align: ${props => props.isImage ?"left":"center"};
  }

  @media (max-width: 1000px) {
    margin-bottom: ${props => props.titleLength === 0 ? 0 : "1rem!important"};
    .title {
      font-size: 1.3rem !important;
      text-align: center;
    }
  }
  
  @media (max-width: 600px) {
    .title {
      font-size: 1.3rem;
    }
    .subtitle {
      font-size: 1.1rem!important;
    }
  }
  @media (max-width: 1000px) {
    width: 100%;
    .title {
      font-size: 1.5rem;
    }
  }

`;
export const MaiCardSection = ({id, title, description, image, tiled, type}: IData) => {
    return (
        <MaiCard isImage={image !==null&&image.length!==0} key={id}>
            <MaiLeftSide className="mai-left-side">
                <img loading={"lazy"} src={URL_IMAGE + image} alt={image}/>
            </MaiLeftSide>
            <MaiRightSide isImage={image !==null&&image.length!==0} titleLength={title.length}>
                <h1 className="title">{title}</h1>
                {tiled ? (
                    <MaiCardTiledContent
                        data={description}
                    />
                ) : description.map(item => (<p key={item} className="subtitle">{item}</p>))
                }
            </MaiRightSide>
        </MaiCard>
    );
}

interface ICard {
    data: string[]
}
const MaiListTiled = styled.div<{ index: number }>`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding-inline:2rem ;
  position: relative;
  span {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 0.7rem;
    background-color: #c7c6c6;
    animation-name:colorChangeAnimation;
    animation-duration:2s;
    animation-timing-function: cubic-bezier(.46,.05,.06,1.32);
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-delay: ${(props) =>props.index * 300}ms;
    position: absolute;
    left: 0;
    display: inline-block;
  }
  @keyframes colorChangeAnimation {
    to {
      background-color: #d70b51;
      left: 0.5rem;
    }
  }
  .enum {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  @media (max-width: 1000px) {
    span {
      border-top-width: 10px;
      border-left-width: 10px;
      border-bottom-width: 10px;
    }
  }
  @media (max-width: 600px) {
    .enum {
      font-size: 1.1rem !important;
    }
  }
`;
const MaiCardTiledContent = ({data}: ICard) => (
    <div className="right-side">
        {data.map((value: string,index:number) => (
            <MaiListTiled key={value} index={index}>
                <span></span>
                <p className="enum">{value}</p>
            </MaiListTiled>
        ))}
    </div>
);
