import {MaiButtonLogOut, MaiLogo,MaiNavAdmin} from '../../style/MaiNavbarAdmin.styled';
import Logo from "../../Images/logo_transparent.png";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import styled from "styled-components";
import MaiLoginChangePwd from "./MaiLoginChangePwd";

export const MaiNavbarAdmin = () => {
    const navigate = useNavigate();
    const [isOpen,setIsOpen] = useState(false);
    const onHandleSignOut=()=>{
        sessionStorage.clear();
        navigate("/")
    }
    const onHandleOpenModel=()=>setIsOpen(true);
    const onHandleCloseModel=()=>setIsOpen(false);
    return (
        <>
        <MaiNavAdmin id="mai-nav">
            <MaiLogo className="logo">
                <img src={Logo} alt="odas-logo"/>
            </MaiLogo>

            <div className="mai-action-right">
                <MaiButtonPwd onClick={()=>onHandleOpenModel()}>
                    Changer mot de passe
                </MaiButtonPwd>
                <MaiButtonLogOut onClick={onHandleSignOut}>
                    LogOut
                </MaiButtonLogOut>
            </div>
        </MaiNavAdmin>
            <MaiLoginChangePwd onHandleModelClose={()=>onHandleCloseModel()} isOpen={isOpen}/>
            </>
    );
};

const MaiButtonPwd=styled.button`
  border: none;
  outline: none;
  margin-right: 5rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #059bef;
    color: #fff;
  }
`
