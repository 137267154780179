import styled from "styled-components";

interface IAlert {
    title: string;
    content: string;
    type: string,
    style:{}
}

const ALERT_TYPE = {
    ERROR: "error",
    SUCCESS: "success",
    INFO: "info",
}
export const Alert = ({title, content, type,style}: IAlert) => {
    return <AlertContainer style={style} alertType={configType(type)}>
        <h3>{title}</h3>
        <p>{content}</p>
    </AlertContainer>
}
const configType = (type: string) => {
    switch (type) {
        case ALERT_TYPE.SUCCESS:
            return "rgba(0,206,87,0.3)";
        case ALERT_TYPE.ERROR:
            return "rgba(206,0,34,0.3)";
        case ALERT_TYPE.INFO:
            return "rgba(0,135,206,0.3)"
        default:
           return "rgba(206,0,34,0.3)";
    }
}
const AlertContainer = styled.div<{alertType:string}>`
  padding: 1rem;
  background-color: ${props => props.alertType};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem;
  border-radius: 1rem;
  gap: 0.5rem;
  transition: 0.5s;
`;